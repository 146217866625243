import SpecialNodeKind from 'enums/SpecialNodeKind';
import FlowNode from 'types/FlowNode';
import RobotCategory from 'enums/RobotCategory';

function getNodes(flow) {
  if (!flow.nodes) return [];

  return flow.nodes.map((node) => {
    const clonedNode = { ...node };
    if (isScriptNode(node)) {
      delete clonedNode.config;
    }
    return clonedNode;
  });
}

function getEdges(flow) {
  return flow.edges || [];
}

function getVariables(flow) {
  return flow.variables || [];
}

function getScriptCells(flow) {
  const scriptCells = {};
  if (flow.nodes) {
    flow.nodes
      .filter((node) => isScriptNode(node))
      .forEach((scriptNode) => {
        const { id, config } = scriptNode;
        scriptCells[id] = config.cells;
      });
  }
  return scriptCells;
}

function isScriptNode(node: FlowNode) {
  return node.kind === SpecialNodeKind.Script;
}

function getEnvironment(flow, robotCategory: RobotCategory) {
  if (flow.environment) {
    return flow.environment;
  }
  let requirements = ['hcl==1.0.15'];
  if (robotCategory === RobotCategory.Highbond) {
    requirements.push('boxsdk==3.0.1', 'openpyxl==3.0.9', 'Jinja2==3.0.3', 'simple-salesforce==1.11.5', 'jira==3.1.1');
  }
  return {
    runtime: 'python3.8',
    requirements,
  };
}

export default {
  getNodes,
  getEdges,
  getEnvironment,
  getScriptCells,
  getVariables,
};
